angular.module("app")
    .controller("staffController", ["$scope", "$rootScope", "$q", "$log", "$timeout", "$filter", "$stateParams", "staffService", "paymentMethodService", "currentOrganisation", "selectedDepartment", function ($scope, $rootScope, $q, $log, $timeout, $filter, $stateParams, staffService, paymentMethodService, currentOrganisation, selectedDepartment) {
        var vm = this;
        vm.staff = [];
        vm.paymentMethodReference = $stateParams.paymentMethodReference;
        vm.organisationReference = $stateParams.organisationReference;
        vm.paymentMethod = undefined;
        vm.exportUrl = undefined;
        vm.inActive = false;

        vm.search = {
            name: undefined,
            mobileNumber: undefined
        };

        vm.pagedSet = {
            page: 0
        };
        vm.pageNotReady = vm.loadingStaff = true;
        vm.disableLoadMore = vm.includeInactive = vm.loadingMore = false;
        vm.children = true;

        vm.loadMore = loadMore;
        vm.listStaff = listStaff;
        vm.setExportUrl = setExportUrl;

        function init() {
            if (angular.isDefined($stateParams.organisationReference)) {
                selectedDepartment.setDepartment($stateParams.organisationReference);
            }
        }

        init();

        function loadMore() {
            listStaff(vm.pagedSet.page);
            if (angular.isDefined(vm.paymentMethodReference)) {
                paymentMethodService.get(vm.organisationReference, vm.paymentMethodReference)
                    .then(function (result) {
                        vm.paymentMethod = result;
                    });
            }
        }

        vm.clearSearch = function () {
            vm.search = {
                name: undefined,
                mobileNumber: undefined
            };
            vm.includeInactive = false;
        };

        function listStaff(page) {
            if (page === 0) {
                vm.staff = [];
            }
            vm.loadingStaff = vm.disableLoadMore = vm.loadingMore = true;

            var params = {
                page: page,
                includeInactive: vm.inActive,
                children: vm.children,
                name: vm.search.name,
                mobileNumber: vm.search.mobileNumber,
                paymentMethodReference: vm.paymentMethodReference,
                sort: 'lastName'
            };

            return staffService.list(params)
                .then(function (pagedSet) {
                    vm.pagedSet = pagedSet;
                    vm.staff = _.concat(vm.staff, pagedSet.results);
                    vm.disableLoadMore = (pagedSet.page >= pagedSet.totalPages);
                }, function (error) {
                    $log.debug(error);
                })
                ['finally'](function () {
                vm.inActiveLoading = vm.loadingStaff = vm.pageNotReady = vm.loadingMore = false;
            });
        }

        function setExportUrl() {
            currentOrganisation.getOrganisationReference().then(function (organisationReference) {
                vm.exportUrl = sprintf('/staff/export?includeInactive=%s&children=true&organisationReference=%s', vm.inActive, organisationReference);
                if (!_.isUndefined(vm.search.name)) vm.exportUrl += '&name=' + vm.search.name;
                if (!_.isUndefined(vm.search.mobileNumber)) vm.exportUrl += '&mobileNumber=' + vm.search.mobileNumber;
            });
        }

        // getExportUrl();

        function modalConfirm() {
            return $uibModal.open({
                templateUrl: '/static/templates/staff-add.html',
                resolve: {},
                controller: 'staffAddController as ctrl'
            }).result;
        }

        vm.removeStaff = function (organisationReference, staffReference) {
            vm.loading = true;
            return staffService.removeStaff(organisationReference, staffReference)
                .then(function (results) {
                    listStaff(0);
                })
                ['finally'](function () {
                vm.loading = false;
            });
        };
    }]);